import React from 'react';
import ContactManagement from '../../../component/Dashboard/Contact/List';



const DashboardContact = () => {
  return (
    <main className='dashboard'>
     <ContactManagement/>
    </main>
  );
};

export default DashboardContact;
import React from 'react';
import Cta from '../../component/Home/CTA';
import Footer from '../../component/Footer/Footer';
import WorkHero from '../../component/Work/HeroWork';

const WorkPage = () => {
  return (
    <main>
      <WorkHero/>
      <Cta/>
      <Footer/>
    </main>
  );
};

export default WorkPage;
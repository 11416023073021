import React from 'react';
import BlogManagement from '../../component/Dashboard/BlogManagement';



const DashboardBlog = () => {
  return (
    <main className='dashboard'>
    <BlogManagement/>
    </main>
  );
};

export default DashboardBlog;
import React from 'react';
import Dashboard from '../../component/Dashboard/Dashboard';



const DashboardPage = () => {
  return (
    <main className='dashboard'>
    <Dashboard/>
    </main>
  );
};

export default DashboardPage;
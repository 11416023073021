import Navbar from "../Navbar/Navbar";
import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
export default function ServiceHero() {
  return (
    <>
      <section 
        class="w-full px-6 pb-12  bg-white"
      
      >
        <Navbar/>
        <div class="mx-auto max-w-6xl">
          <div class="container  px-4 py-32 mx-auto mt-px text-left md:max-w-none md:text-center">
          <p  className="text-4xl lg:leading-[60px] sm:leading-[103px] font-bold  text-gray-900 sm:text-5xl  lg:text-6xl animate__animated animate__bounce">
          We partner with you to provide innovative, cloud-based digital solutions tailored to elevate your business.  Our strategic concept comes with the intent to scale, systemize, and sustain growth for clients.
        
          </p>

        
          </div>
        </div>
        <section class="w-full pt-7 pb-7 md:pt-20 md:pb-24">
    <div class="box-border flex flex-col items-center content-center px-8 mx-auto leading-6 text-black border-0 border-gray-300 border-solid md:flex-row max-w-7xl lg:px-16">

       
        <div class="box-border relative w-full max-w-md px-4 mt-5 mb-4 -ml-5 text-center bg-no-repeat bg-contain border-solid md:ml-0 md:mt-0 md:max-w-none lg:mb-0 md:w-1/2 xl:pl-10">
            <img src="/img/innovate.svg" class="p-2 pl-6 pr-5 xl:pl-16 xl:pr-20 " />
        </div>

      
        <div class="box-border order-first w-full text-black border-solid md:w-1/2 md:pl-10 md:order-none">
            <h1 class="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
            Explore thoroughly
            </h1>
            <p class="pt-4 pb-10 m-0 leading-7 text-gray-600 border-0 border-gray-300 sm:pr-12 xl:pr-32 lg:text-2xl">
            At DevDesk, we prioritize in-depth research. By thoroughly understanding your business culture, market trends, and user expectations, we discover critical insights that shape our strategic direction.
            </p>
           
        </div>
    </div>
    <div class="box-border flex flex-col items-center content-center px-8 mx-auto mt-10 leading-6 text-black border-0 border-gray-300 border-solid md:mt-20 xl:mt-0 md:flex-row max-w-7xl lg:px-16">

       
        <div class="box-border lg:mt-20 w-full text-black  border-solid md:w-1/2 md:pl-6 xl:pl-32">
        <h1 class="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
        Innovate confidently
            </h1>
            <p class="pt-4 pb-10 m-0 leading-7 text-gray-600 border-0 border-gray-300 sm:pr-12 xl:pr-32 lg:text-2xl">
            With our comprehensive research as a foundation, we fuel bold creativity. From compelling brand stories and dynamic websites to scalable software solutions, our designs are crafted to inspire your team and captivate your audience.
            </p>
           
        </div>

       
        <div class="box-border relative w-full max-w-md px-4 mt-10 mb-4 text-center bg-no-repeat bg-contain border-solid md:mt-0 md:max-w-none lg:mb-0 md:w-1/2">
            <img src="/img/xplore.svg" class="pl-4 sm:pr-10 xl:pl-10 lg:pr-32" />
        </div>
    </div>
    <div class="box-border flex flex-col items-center content-center px-8 mx-auto leading-6 text-black border-0 border-gray-300 border-solid md:flex-row max-w-7xl lg:px-16">

       
<div class="box-border relative w-full max-w-md px-4 mt-5 mb-4 -ml-5 text-center bg-no-repeat bg-contain border-solid md:ml-0 md:mt-0 md:max-w-none lg:mb-0 md:w-1/2 xl:pl-10">
    <img src="/img/deliver.svg" class="p-2 pl-6 pr-5 xl:pl-16 xl:pr-20 " />
</div>


<div class="box-border order-first w-full text-black border-solid md:w-1/2 md:pl-10 md:order-none">
    <h1 class="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
    Deliver Dependably
    </h1>
    <p class="pt-4 pb-10 m-0 leading-7 text-gray-600 border-0 border-gray-300 sm:pr-12 xl:pr-32 lg:text-2xl">
    Harnessing the power of technology, we bring our designs to life. We develop reliable and adaptable websites and software, transforming concepts into digital realities. Our goal is to not only meet your current needs but also to push the boundaries of your future vision.
    </p>
   
</div>
</div>
<div class="box-border flex flex-col items-center content-center px-8 mx-auto mt-10 leading-6 text-black border-0 border-gray-300 border-solid md:mt-20 xl:mt-0 md:flex-row max-w-7xl lg:px-16">

       
<div class="box-border lg:mt-20 w-full text-black  border-solid md:w-1/2 md:pl-6 xl:pl-32">
<h1 class="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
Optimize Continuously
    </h1>
    <p class="pt-4 pb-10 m-0 leading-7 text-gray-600 border-0 border-gray-300 sm:pr-12 xl:pr-32 lg:text-2xl">
    At DevDesk, we believe in relentless improvement. We continually refine and enhance your digital solutions with ongoing analysis and strategic optimization.
    </p>
   
</div>


<div class="box-border relative w-full max-w-md px-4 mt-10 mb-4 text-center bg-no-repeat bg-contain border-solid md:mt-0 md:max-w-none lg:mb-0 md:w-1/2">
    <img src="/img/optimize.svg" class="pl-4 sm:pr-10 xl:pl-10 lg:pr-32" />
</div>
</div>
</section>

<div className="mx-auto mt-20 max-w-5xl text-center">
          <p className="text-4xl  text-black sm:text-5xl  lg:text-5xl animate__animated animate__bounce">
         
          We are on a mission to help people and businesses succeed, we optimizes
              business process as a service (BPaaS) to power workplace
              collaboration, meets client’s satisfaction and create a leveraging
              ground for business acceleration.
           </p>
          <div className="mt-8 sm:items-center sm:justify-center lg:justify-start sm:space-x-5 lg:mt-12">
                <Link
                  href="#"
                  title=""
                  className="inline-flex items-center px-4 py-2 text-lg text-white transition-all duration-200 bg-[#0186ef] border border-transparent rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj justify-center hover:bg-gray-600"
                  role="button"
                >
                  {" "}
                  Let’s Collaborate{" "} <ArrowLongRightIcon className="ml-2 w-8 h-8"/>
                </Link>
               
              </div>
        </div>
      </section>
      

     

    </>
  );
}

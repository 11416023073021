import React from 'react';
import NewsletterManagement from '../../../component/Dashboard/Newsletter/List';



const DashboardNewsletter = () => {
  return (
    <main className='dashboard'>
     <NewsletterManagement/>
    </main>
  );
};

export default DashboardNewsletter;
import React from 'react';
import Cta from '../../component/Home/CTA';
import Footer from '../../component/Footer/Footer'
import AboutHero from '../../component/About/HeroAbout';

const AboutPage = () => {
  return (
    <main>
      <AboutHero/>
      <Cta/>
      <Footer/>
    </main>
  );
};

export default AboutPage;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from '../Navbar/Navbar';
import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

export default function WorkHero() {
  const [portfolio, setPortfolio] = useState([]);

  useEffect(() => {
    const fetchPortfolio = async () => {
      try {
        const response = await axios.get('https://app.devdesk.ng/api/portfolios'); // Update with your API endpoint
        setPortfolio(response.data);
      } catch (error) {
        console.error('Error fetching portfolio data:', error);
      }
    };

    fetchPortfolio();
  }, []);

  return (
    <>
      <Navbar />
      <section className="w-full px-6 pb-12 bg-white">
        <div className="mx-auto lg:max-w-6xl">
          <div className="container py-16 mx-auto mt-px text-left md:max-w-none md:text-left">
            <p className="text-4xl lg:leading-[138px] tracking-tighter sm:leading-[103px] font-bold text-gray-900 sm:text-5xl lg:text-9xl animate__animated animate__bounce">
              What we do, and the people we serve.
            </p>
          </div>
        </div>

        <section className="px-2 py-32 bg-gray-50 md:px-0">
          <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
            {portfolio.length > 0 && (
              <div className="flex flex-wrap items-center sm:-mx-3">
                <div className="w-full">
                  <div className="w-full h-auto overflow-hidden rounded-md shadow-xl sm:rounded-xl" data-rounded="rounded-xl" data-rounded-max="rounded-full">
                    <img className="hover:translate-x-10" src={portfolio[0].featured_image} alt={portfolio[0].title} />
                  </div>
                  <div className="mt-20 mx-auto max-w-4xl lg:mx-0">
                    <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl hover:translate-y-2 hover:underline">{portfolio[0].title}</h2>
                    <p className="mt-2 text-[18px] font-bold leading-8 text-black">{portfolio[0].description}</p>
                  </div>
                </div>

                <div className="mt-10">
                  <button
                    type="button"
                    className="rounded-full hover:translate-y-2 inline-flex bg-white px-4 py-2.5 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    User Interface
                  </button>
                  <button
                    type="button"
                    className="ml-3 hover:translate-y-2 rounded-full inline-flex bg-white px-4 py-2.5 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Backend Development
                  </button>
                  <button
                    type="button"
                    className="ml-3 hover:translate-y-2 rounded-full inline-flex bg-white px-4 py-2.5 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Brand Strategy
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>

        {/* Category section */}
        <section aria-labelledby="category-heading" className="bg-gray-50">
          <div className="mx-auto max-w-7xl px-4 py-24 sm:px-6 sm:py-32 lg:px-8">
            <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 lg:gap-8">
              {portfolio.slice(1).map((post) => (
                <div key={post.id} className="group rounded-lg sm:aspect-none sm:relative sm:h-full">
                  <img
                    src={post.featured_image}
                    alt={post.title}
                    className="object-cover object-center group-hover:opacity-75 sm:absolute sm:inset-0 sm:h-full sm:w-full"
                  />
                  <div className="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0" aria-hidden="true" />
                  <div className="mx-auto max-w-4xl lg:mx-0">
                    <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl hover:translate-y-2 hover:underline">{post.title}</h2>
                    <p className="mt-2 text-[18px] font-bold leading-8 text-black">{post.description}</p>
                  </div>
                  <div className="mt-10">
                    <button
                      type="button"
                      className="rounded-full hover:translate-y-2 inline-flex bg-white px-4 py-2.5 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Development
                    </button>
                    <button
                      type="button"
                      className="ml-2 rounded-full hover:translate-y-2 inline-flex bg-white px-4 py-2.5 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Branding
                    </button>
                  </div>

                  <div className="mx-auto max-w-4xl lg:mx-0">
                    <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl hover:translate-y-2 hover:underline">{post.title}</h2>
                    <p className="mt-2 text-[18px] font-bold leading-8 text-black">{post.description}</p>
                  </div>
                </div>
                
              ))}
            </div>
          </div>
        </section>

        {/* Additional Portfolio Section */}
        <div className="mx-auto mt-40 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {portfolio.map((post) => (
            <article key={post.id} className="flex flex-col items-start justify-between">
              <div className="relative w-full">
                <img
                  src={post.featured_image}
                  alt={post.title}
                  className="aspect-[16/9] w-full bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="max-w-xl">
                <div className="group relative mt-10">
                  <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl hover:translate-y-2 hover:underline">
                    <a className="work-anibe" href={post.href}>
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h2>
                  <p className="mt-5 text-[18px] font-bold leading-8 text-black">{post.description}</p>
                </div>
                <div className="mt-4">
                  <button
                    type="button"
                    className="rounded-full hover:translate-y-2 inline-flex bg-white px-4 py-2.5 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Development
                  </button>
                  <button
                    type="button"
                    className="rounded-full ml-2 hover:translate-y-2 inline-flex bg-white px-4 py-2.5 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Branding
                  </button>
                </div>
              </div>
            </article>
          ))}
        </div>

        <div className="mx-auto mt-20 max-w-5xl text-center">
          <p className="text-4xl text-black sm:text-5xl lg:text-5xl animate__animated animate__bounce">
            DevDesk serves more than 106 clients globally, ranging from large established
            companies to emerging start-ups and personal brand as their consulting firm,
            development strategist and software service provider; specializing on business,
            technology, innovation, development strategy, full package branding and
            internet marketing.
          </p>
          <div className="mt-8 sm:items-center sm:justify-center lg:justify-start sm:space-x-5 lg:mt-12">
            <Link
              to="#"
              title=""
              className="inline-flex items-center px-4 py-2 text-lg text-white transition-all duration-200 bg-[#0186ef] border border-transparent rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj justify-center hover:bg-gray-600"
              role="button"
            >
              Let’s Collaborate <ArrowLongRightIcon className="ml-2 w-8 h-8" />
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}

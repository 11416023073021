import React from 'react';
import Hero from '../../component/Home/Hero';
import About from '../../component/Home/About';
import Feature from '../../component/Home/Feature';
import Cta from '../../component/Home/CTA';
import Footer from '../../component/Footer/Footer';
import Blog from '../../component/Home/Blog';

const HomePage = () => {
  return (
    <main>
      <Hero/>
      <About/>
      <Feature/>
      <Blog/>
      <Cta/>
      <Footer/>
    </main>
  );
};

export default HomePage;
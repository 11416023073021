import { useParams, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

export default function Post() {
  const { postId } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPostDetails = async () => {
      if (!postId) return;

      try {
        const response = await fetch(`https://app.devdesk.ng/api/posts/${postId}`);
        if (response.ok) {
          const result = await response.json();
          setPost(result); // Ensure you're setting the entire result, not just result.data
        } else {
          console.error('Failed to fetch post details:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching post details:', error);
      }
    };

    fetchPostDetails();
  }, [postId]);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-4xl text-[18px] px-6 lg:px-8">
        <h1 className="text-4xl font-bold mb-6 text-black">{post.title}</h1>
        <div className="prose max-w-none mb-12">
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {post.content}
          </ReactMarkdown>
        </div>
        <Link to="/blog" className="text-blue-500 hover:underline">Back to Blog</Link>
      </div>
    </div>
  );
}

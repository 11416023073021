import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

export default function Blog() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get('https://app.devdesk.ng/api/posts?limit=3'); // Fetching only 3 posts
        if (response.status === 200) {
          setPosts(response.data); // Assuming your API returns an array of posts
        } else {
          console.error('Failed to fetch posts:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, []);

  return (
    <div className="bg-white py-20 sm:py-20">
      <div className="mx-auto max-w-8xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-4xl font-bold text-gray-900 sm:text-5xl lg:text-6xl animate__animated animate__bounce">
            Articles & News
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Stay tuned into our latest endeavors, insightful articles, and the industry trends. Fresh insights delivered weekly.
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article
              key={post.id}
              className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"
            >
              <img src={post.feature_image} alt={post.title} className="absolute inset-0 -z-10 h-full w-full object-cover" />
              <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
              <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

              <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
                <time dateTime={post.datetime} className="mr-8">
                  {post.date}
                </time>
                
              </div>
              <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
              <Link to={`/post/${post.id}`}>
                          <span className="absolute inset-0" />
                          {post.title}
                        </Link>
              </h3>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
}

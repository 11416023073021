import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, Navigate } from 'react-router-dom';
import {
  Dialog,
  Transition
} from '@headlessui/react';
import {
  BookOpenIcon,
  BriefcaseIcon,
  CircleStackIcon,
  Cog6ToothIcon,
  EnvelopeIcon,
  EnvelopeOpenIcon,
  HomeModernIcon,
  UserPlusIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';

const navigation = [
  { name: 'Dashboard', href: '/dashboard', icon: HomeModernIcon },
  { name: 'Blog', href: 'dashboard/blog', icon: BookOpenIcon },
  { name: 'Contact', href: 'dashboard/contact', icon: EnvelopeOpenIcon },
  { name: 'Job', href: 'dashboard/job', icon: BriefcaseIcon },
  { name: 'Portfolio', href: 'dashboard/portfolio', icon: CircleStackIcon },
  { name: 'Admin', href: 'dashboard/admin', icon: UserPlusIcon },
  { name: 'Newsletter', href: 'dashboard/newsletter', icon: EnvelopeIcon },
];

const teams = [
  { id: 1, name: 'Update coming soon', href: '#', initial: 'A' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const API_URL = 'https://app.devdesk.ng/api';
  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        return <Navigate to="/login" />;
      }

      try {
        const response = await axios.get(`${API_URL}/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log('User data:', response.data);
        setUserData(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          <Navigate to="/login" />
        } else {
          console.error('Error fetching user data:', error);
          // Handle other errors (e.g., display error message)
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Transition show={sidebarOpen} as={React.Fragment}>
        <Dialog
          open={sidebarOpen}
          onClose={setSidebarOpen}
          className="relative z-50 xl:hidden"
        >
          <Transition.Child
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>
          <div className="fixed inset-0 flex">
            <Transition.Child
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-900 p-6">
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button
                    type="button"
                    onClick={() => setSidebarOpen(false)}
                    className="-m-2.5 p-2.5"
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex h-16 items-center">
                  <img
                    alt="DevDesk"
                    src="/img/4.png"
                    className="h-8 w-auto"
                  />
                </div>
                <nav className="flex flex-1 flex-col mt-5">
                  <ul role="list" className="flex-1 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            'text-gray-400 hover:bg-gray-800 hover:text-white',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                          )}
                        >
                          <item.icon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                  <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {teams.map((team) => (
                      <li key={team.name}>
                        <a
                          href={team.href}
                          className={classNames(
                            'text-gray-400 hover:bg-gray-800 hover:text-white',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                          )}
                        >
                          <span className="flex h-6 w-6 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-xs font-medium text-gray-400 group-hover:text-white">
                            {team.initial}
                          </span>
                          <span className="truncate">{team.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </nav>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      <div className="hidden xl:fixed xl:inset-y-0 xl:flex xl:w-72 xl:flex-col bg-gray-900">
        <div className="flex flex-1 flex-col overflow-y-auto bg-gray-900 p-6">
          <div className="flex h-16 items-center">
            <img
              alt="DevDesk"
              src="/img/4.png"
              className="h-8 w-auto"
            />
          </div>
          <nav className="flex flex-1 flex-col mt-5">
            <ul role="list" className="flex-1 space-y-1">
              {navigation.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.href}
                    className={classNames(
                      'text-gray-400 hover:bg-gray-800 hover:text-white',
                      'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                    )}
                  >
                    <item.icon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
            <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
            <ul role="list" className="-mx-2 mt-2 space-y-1">
              {teams.map((team) => (
                <li key={team.name}>
                  <a
                    href={team.href}
                    className={classNames(
                      'text-gray-400 hover:bg-gray-800 hover:text-white',
                      'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                    )}
                  >
                    <span className="flex h-6 w-6 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-xs font-medium text-gray-400 group-hover:text-white">
                      {team.initial}
                    </span>
                    <span className="truncate">{team.name}</span>
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>

      <div className="xl:pl-72 py-4">
        <main className="lg:pr-96">
          <h1 className="mx-auto ml-10 font-bold text-4xl">Dashboard</h1>
          {userData && (
            <h1 className="ml-10 mt-5 text-2xl font-normal">
              Hello, <span className="text-[#0186ef] ml-1">{userData.name}</span>
            </h1>
          )}
        </main>
      </div>
    </div>
  );
}

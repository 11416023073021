import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import axios from 'axios';
import Navbar from "../Navbar/Navbar";

export default function BlogHero() {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 6; // Number of posts per page

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const response = await axios.get('https://app.devdesk.ng/api/posts'); // Replace with your actual API endpoint
      setPosts(response.data);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  // Calculate current posts for pagination
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // Ensure posts is defined and has length before slicing
  const currentPosts = posts && posts.length > 0 ? posts.slice(indexOfFirstPost, indexOfLastPost) : [];

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <>
      <Navbar />
      <section className="w-full px-6 pb-12 bg-white">
        <div className="mx-auto lg:max-w-6xl">
          <div className="container py-8 mx-auto mt-px text-left md:max-w-none md:text-left">
            <p className="text-4xl lg:leading-[138px] tracking-tighter sm:leading-[103px] font-bold text-gray-900 sm:text-5xl lg:text-9xl animate__animated animate__bounce">
              Articles
            </p>
          </div>
          <div className="mt-4">
            <button
              type="button"
              className="rounded-full hover:translate-y-2 inline-flex bg-[#b7e8dd] px-8 py-4 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              All
            </button>
            <button
              type="button"
              className="ml-2 rounded-full hover:translate-y-2 inline-flex bg-[#b7e8dd] px-8 py-4 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Design
            </button>
            {/* Add buttons for categories */}
          </div>
        </div>

        <section className="px-2 md:px-0">
          <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
            <div className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {currentPosts.map((post) => (
                <article key={post.id} className="flex flex-col items-start justify-between">
                  <div className="relative w-full">
                    <img
                      src={post.feature_image}
                      alt=""
                      className="aspect-[16/9] w-full bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                    />
                    <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                  <div className="max-w-xl">
                    <div className="group relative mt-10">
                      <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl hover:translate-y-2 hover:underline">
                        <Link to={`/post/${post.id}`}>
                          <span className="absolute inset-0" />
                          {post.title}
                        </Link>
                      </h2>
                      <p className="mt-5 text-[18px] w-[290px] font-bold leading-8 text-black truncate">{post.content}</p>
                    </div>
                    <div className="mt-4">
                      <button
                        type="button"
                        className="rounded-full hover:translate-y-2 inline-flex bg-white px-4 py-2.5 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        {post.category}
                      </button>
                      {/* Add buttons for categories */}
                    </div>
                  </div>
                </article>
              ))}
            </div>
          </div>

          <div className="flex items-center justify-center mt-8">
            {Array.from({ length: Math.ceil(posts.length / postsPerPage) }, (_, index) => (
              <button
                key={index}
                className={`mx-2 px-4 py-2 rounded-full text-sm font-medium focus:outline-none ${currentPage === index + 1 ? 'bg-gray-900 text-white' : 'bg-gray-200 text-gray-700'}`}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </section>
      </section>
    </>
  );
}

import React, { useState } from "react";
import axios from "axios";

export default function Cta() {
  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setEmail(e.target.value);
    if (error) {
      setError(null); // Clear the error when user starts typing
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await axios.post('https://app.devdesk.ng/api/newsletters', { email });
      console.log('Subscription successful:', response.data);
      setSuccess('Thank you for subscribing to our newsletter');
      setEmail(""); // Clear the input field
    } catch (error) {
      console.error('Error subscribing:', error);
      if (error.response) {
        setError(error.response.data.errors.email || 'Error subscribing. Please try again.');
      } else {
        setError('Something went wrong. Please try again later.');
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="bg-[#03020c]">
      <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-4xl font-bold text-gray-100 sm:text-5xl lg:text-6xl animate__animated animate__bounce">
            Subscribe to DevDesk Newsletter
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-100">
            Get up-to-date information about us, our work, and research.
          </p>
          <form onSubmit={handleSubmit} className="relative flex flex-col items-center max-w-md mx-auto mt-12 text-center">
            <input
              type="text"
              name="email"
              placeholder="Enter your email address"
              value={email}
              onChange={handleChange}
              className={`w-full rounded-full h-12 px-6 py-2 font-medium text-indigo-800 focus:outline-none border ${
                error ? 'border-red-500' : 'border-[#0186ef]'
              }`}
            />
            {error && <div className="mt-2 text-red-600">{error}</div>}
            <span className="relative top-0 right-0 block mt-4">
              <button
                type="submit"
                disabled={submitting}
                className={`inline-flex rounded-full items-center w-32 h-12 px-8 text-base font-bold leading-6 text-white transition duration-150 ease-in-out bg-[#0186ef] border border-transparent hover:bg-gray-600 focus:outline-none active:bg-gray-600 ${
                  submitting ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {submitting ? 'Submitting...' : 'Subscribe'}
              </button>
            </span>
          </form>
          {success && <div className="mt-4 text-green-600">{success}</div>}
        </div>
      </div>
    </div>
  );
}

import { Link } from "react-router-dom"
const navigation = {
   
    social: [
      {
        name: 'Linkedin',
        href: 'https://linkedin.com/',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 62 62" {...props}
    >
      <path
        strokeLinejoin="round"
        strokeWidth="0.2"
        d="M39.398 36.138l.037-.054v.054m11.783 14.67h-5.74V41.63c0-2.307-.832-3.88-2.907-3.88-1.588 0-2.53 1.061-2.945 2.086-.153.367-.19.879-.19 1.39v9.583h-5.74s.076-15.547 0-17.156h5.74v2.432c.76-1.169 2.123-2.836 5.171-2.836 3.778 0 6.61 2.45 6.61 7.723m-23.566-9.663h-.039c-1.925 0-3.173-1.317-3.173-2.965 0-1.682 1.285-2.963 3.248-2.963 1.964 0 3.171 1.28 3.21 2.963 0 1.648-1.246 2.965-3.246 2.965zm2.87 19.5h-5.742V33.652h5.742M54.194 19H21.808C20.258 19 19 20.218 19 21.722v32.554C19 55.78 20.258 57 21.808 57h32.386C55.745 57 57 55.78 57 54.276V21.722C57 20.218 55.745 19 54.194 19z"
      ></path>
    </svg>
     
        ),
      },

      {
        name: 'Facebook',
        href: 'https://www.facebook.com/profile.php?id=100087914272289',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: 'Instagram',
        href: '#',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: 'Whatsapp',
        href: 'tel:08020595269',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 21 21" {...props}>
  <path
    fillRule="evenodd"
    
    d="M17.6 6.32C16.867 5.581 15.994 4.996 15.033 4.598 14.072 4.199 13.041 3.996 12 4c-1.391.001-2.758.368-3.963 1.064C6.833 5.759 5.832 6.759 5.135 7.963 4.439 9.167 4.07 10.533 4.068 11.925 4.065 13.316 4.428 14.683 5.12 15.89L4 20l4.2-1.1C9.36 19.545 10.663 19.889 11.99 19.9c2.11.001 4.134-.833 5.632-2.318 1.498-1.485 2.349-3.502 2.368-5.612-.007-1.053-.222-2.094-.632-3.063-.41-.97-1.008-1.85-1.758-2.588ZM12 18.53c-1.182-.001-2.343-.318-3.36-.92L8.4 17.46 5.91 18.12l.66-2.43-.16-.25c-.851-1.373-1.168-3.011-.892-4.603.276-1.592 1.126-3.027 2.39-4.034 1.263-1.007 2.852-1.515 4.465-1.429 1.613.084 3.138.759 4.287 1.895 1.256 1.228 1.975 2.903 2 4.66-.016 1.756-.725 3.434-1.972 4.67-1.248 1.236-2.933 1.93-4.69 1.93ZM15.61 13.59c-.2-.1-1.17-.58-1.35-.64-.18-.06-.32-.1-.45.1-.196.268-.406.525-.63.77-.11.14-.23.15-.43 0-1.14-.45-2.09-1.28-2.69-2.35-.21-.35.2-.33.58-1.08.028-.054.043-.114.043-.175s-.015-.121-.043-.175c0-.1-.45-1.08-.61-1.47-.16-.39-.32-.33-.45-.34H9.19c-.1.002-.199.025-.29.068-.091.043-.172.105-.237.182-.224.218-.399.482-.513.774-.114.291-.164.604-.147.917.062.748.344 1.461.81 2.05.852 1.276 2.02 2.31 3.39 3 .718.419 1.553.594 2.38.5.275-.054.536-.166.765-.328.229-.161.421-.37.565-.61.133-.295.175-.622.121-.94-.09-.1-.22-.15-.42-.25Z"
  
  />
</svg>

        
        ),
      },
      {
        name: 'X',
        href: '#',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
          </svg>
        ),
      },
     
    
    ],
  }
  
  export default function Footer() {
    return (
      <>
      <footer class="bg-[#03020c]">
    <div class="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
        <nav class="flex flex-wrap justify-center -mx-5 -my-2">
            <div class="px-5 py-2">
                <Link to="/about" class="text-base leading-6 text-gray-100 hover:text-blue-500">
                    About
                </Link>
            </div>
            <div class="px-5 py-2">
                <Link to="/blog" class="text-base leading-6 text-gray-100 hover:text-blue-500">
                    Blog
                </Link>
            </div>
            <div class="px-5 py-2">
                <Link to="/work" class="text-base leading-6 text-gray-100 hover:text-blue-500">
                    Work
                </Link>
            </div>
            <div class="px-5 py-2">
                <Link to="/services" class="text-base leading-6 text-gray-100 hover:text-blue-500">
                    Services
                </Link>
            </div>
            <div class="px-5 py-2">
                <Link to="/contact" class="text-base leading-6 text-gray-100 hover:text-blue-500">
                    Contact
                </Link>
            </div>
           
        </nav>
        <div class="flex justify-center mt-8 space-x-6">
                {navigation.social.map((item) => (
                  <a key={item.name} href={item.href} className="text-gray-400 hover:text-[#0186ef]">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
                ))}
              </div>
        
        <p class="mt-8 text-base leading-6 text-center text-gray-100">
        &copy; Copyright 2024 Devdesk Integrated Services. All rights reserved.
        </p>
        <p class="text-sm text-center text-gray-600">
      Made with <span class="text-red-500 anibe samuel designed this">♥</span> in Abuja, Nigeria
    </p>
    </div>
</footer>


      </>
      
    )
  }
  
import React, { useState } from 'react';
import NavbarContact from '../Navbar/NavbarContact';
import axios from 'axios';

export default function HeroContact() {
  const [formData, setFormData] = useState({
    fullname: '',
    company: '',
    message: '',
    budget: '',
    email: '',
  });
  const [agreed, setAgreed] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null); // State to hold error message
  const [successMessage, setSuccessMessage] = useState(null); // State to hold success message

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setError(null); // Clear previous errors

    try {
      const response = await axios.post('https://app.devdesk.ng/api/contacts', formData);
      console.log('Contact form submitted successfully:', response.data);
      setSuccessMessage('Thank you for contacting DevDesk, you will get a response from us shortly.');
      setFormData({
        fullname: '',
        company: '',
        message: '',
        budget: '',
        email: '',
      });
    } catch (error) {
      console.error('Error submitting contact form:', error);
      if (error.response) {
        // Server responded with a status code outside of 2xx range
        setError(error.response.data.message); // Assuming your backend sends error messages in a 'message' field
      } else {
        // Request was made but no response received
        setError('Something went wrong. Please try again later.'); // Generic error message
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <section className="w-full px-6 pb-12 bg-[#ebecab]">
        <NavbarContact />
        <div className="mx-auto max-w-5xl">
          <div className="container px-4 py-32 mx-auto mt-px text-left md:max-w-none ">
            <p className="text-4xl lg:leading-[138px] tracking-tighter sm:leading-[103px] font-bold text-gray-900 sm:text-5xl lg:text-9xl animate__animated animate__bounce">
              Let’s collaborate on your next big idea
            </p>
            <p className="mt-2 lg:text-4xl sm:leading-1 text-gray-600 sm:mt-8">
              Reach out to discuss your business needs or just to say hello. Use the form below or send us an email at{' '}
              <a href="mailto:info@devdesk.com" className="text-[#0186ef]">
                info@devdesk.com
              </a>
            </p>
          </div>

          <div className="px-6 lg:px-8">
         
            <form onSubmit={handleSubmit} className="mx-auto max-w-xl ">
              <div className="mb-4">
            
              
              {successMessage && (
                <div role="alert" className="alert alert-success">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 shrink-0 stroke-current"
                  fill="none"
                  viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span className=''> {successMessage}</span>
              </div>
               
              )}
              </div>
              {error && (
            <div role="alert" className="alert alert-error">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 shrink-0 stroke-current"
              fill="none"
              viewBox="0 0 24 24">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span> Error: {error}</span>
          </div>
               
              )}
         
              <div className="grid grid-cols-1 gap-x-8 gap-y-6 mt-3 sm:grid-cols-2">
                <div className="sm:col-span-2">
                  <label htmlFor="fullname" className="block font-semibold leading-6 text-gray-900">
                    What is your name?
                  </label>
                  <div className="mt-2.5">
                    <input
                      id="fullname"
                      name="fullname"
                      type="text"
                      autoComplete="fullname"
                      placeholder="Fullname"
                      value={formData.fullname}
                      onChange={handleChange}
                      className="block w-full rounded-md border-0 px-6 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="company" className="block font-semibold leading-6 text-gray-900">
                    What is your Company's name?
                  </label>
                  <div className="mt-2.5">
                    <input
                      id="company"
                      name="company"
                      type="text"
                      autoComplete="Company"
                      placeholder="Company"
                      value={formData.company}
                      onChange={handleChange}
                      className="block w-full rounded-md border-0 px-6 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="message" className="block font-semibold leading-6 text-gray-900">
                    How can we help you?
                  </label>
                  <div className="mt-2.5">
                    <textarea
                      id="message"
                      name="message"
                      rows={4}
                      autoComplete="message"
                      placeholder="Describe your ideas or questions"
                      value={formData.message}
                      onChange={handleChange}
                      className="block w-full resize-none rounded-md border-0 px-6 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="budget" className="block font-semibold leading-6 text-gray-900">
                    Do you have a defined budget?
                  </label>
                  <div className="mt-2.5">
                    <input
                      id="budget"
                      name="budget"
                      type="text"
                      placeholder="Enter your defined budget"
                      autoComplete="budget"
                      value={formData.budget}
                      onChange={handleChange}
                      className="block w-full rounded-md border-0 px-6 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="email" className="block font-semibold leading-6 text-gray-900">
                    What is your email address?
                  </label>
                  <div className="mt-2.5">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Email address"
                      autoComplete="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="block w-full rounded-md border-0 px-6 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
            
              <div className="mt-10">
                <button
                  type="submit"
                  disabled={submitting}
                  className={`block w-full rounded-md bg-[#0186ef] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                    submitting ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  {submitting ? 'Submitting...' : 'Send Message'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

import React from 'react';
import PortfolioManagement from '../../../component/Dashboard/Portfolio/Create';



const DashboardPortfolio = () => {
  return (
    <main className='dashboard'>
    <PortfolioManagement/>
    </main>
  );
};

export default DashboardPortfolio;
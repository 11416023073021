import React from 'react';
import Login from '../../component/Login/Login';


const LoginPage = () => {
  return (
    <div class="h-full login">
        
    <main class="h-full">
    <Login/>
    </main>
    </div>
  );
};

export default LoginPage;
import React from 'react';
import Cta from '../../component/Home/CTA';
import Footer from '../../component/Footer/Footer';
import HeroCareer from '../../component/Career/HeroCareer';

const CareerPage = () => {
  return (
    <main>
      <HeroCareer/>
      <Cta/>
      <Footer/>
    </main>
  );
};

export default CareerPage;
import { useState } from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from "react-router-dom";

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div>
      <header className="bg-white">
        <div className="px-6 py-4 mx-auto sm:px-6 lg:px-20">
          <div className="flex items-center justify-between h-16 lg:h-20">
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center">
                <Link to="/" title="Home" className="flex">
                  <img
                    className="w-auto h-8"
                    src="../../../img/logo.png"
                    alt="Logo"
                  />
                </Link>
              </div>
              <div className="flex lg:hidden">
                <button
                  type="button"
                  className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(true)}
                >
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon className="h-10 w-10" aria-hidden="true" />
                </button>
              </div>
              <div className="hidden lg:flex lg:flex-grow lg:items-center lg:justify-center lg:gap-x-12">
                <Link
                  to="/work"
                  className="text-black transition-all duration-200 hover:text-opacity-80"
                >
                  Work
                </Link>
                <Link
                  to="/about"
                  className="text-black transition-all duration-200 hover:text-opacity-80"
                >
                 About Us
                </Link>
                <Link
                  to="/services"
                  className="text-black transition-all duration-200 hover:text-opacity-80"
                >
                  Services
                </Link>
               
                <Link
                  to="/career"
                  className="text-black transition-all duration-200 hover:text-opacity-80"
                >
                  Career
                </Link>
                <Link
                  to="/blog"
                  className="text-black transition-all duration-200 hover:text-opacity-80"
                >
                  Blog
                </Link>
              </div>
              <div className="hidden lg:flex lg:items-center">
                <Link
                  to="/contact"
                  className="inline-flex items-center rounded-full justify-center px-5 py-2.5 text-white bg-[#0186ef] hover:bg-black hover:text-white transition-all duration-200 focus:bg-black focus:text-white"
                  role="button"
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)}>
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Devdesk</span>
              

<img
                    className="w-auto h-8"
                    src="../../../img/logo.png"
                    alt="Logo"
                  />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="-my-6 mt-3 divide-y divide-gray-500/10">
            <div className="space-y-4 py-6">
              <Link
                to="/work"
                className="block text-black transition-all duration-200 hover:text-opacity-80"
              >
                Work
              </Link>
              <Link
                to="/about"
                className="block text-black transition-all duration-200 hover:text-opacity-80"
              >
                About Us
              </Link>
              <Link
                to="/services"
                className="block text-black transition-all duration-200 hover:text-opacity-80"
              >
                Services
              </Link>
             
              <Link
                to="/career"
                className="block text-black transition-all duration-200 hover:text-opacity-80"
              >
                Career
              </Link>
              <Link
                to="/blog"
                className="block text-black transition-all duration-200 hover:text-opacity-80"
              >
                Blog
              </Link>
            
            </div>
            <div className="py-6">
            <Link
                to="/contact"
                className=" inline-flex items-center rounded-full justify-center px-5 py-2.5 text-white bg-[#0186ef] hover:bg-black hover:text-white transition-all duration-200 focus:bg-black focus:text-white"
                role="button"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </div>
  );
};

export default Navbar;

import React from 'react';
import CreateAdmin from '../../../component/Dashboard/Admin/Create';



const DashboardAdmin = () => {
  return (
    <main className='dashboard'>
    <CreateAdmin/>
    </main>
  );
};

export default DashboardAdmin;
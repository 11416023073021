import React from 'react';
import Cta from '../../component/Home/CTA';
import Footer from '../../component/Footer/Footer';
import BlogHero from '../../component/Blog/HeroBlog';

const BlogPage = () => {
  return (
    <main>
      <BlogHero/>
      <Cta/>
      <Footer/>
    </main>
  );
};

export default BlogPage;
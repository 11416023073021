import React from 'react';
import Cta from '../../component/Home/CTA';
import Footer from '../../component/Footer/Footer';
import HeroContact from '../../component/Contact/HeroContact';

const ContactPage = () => {
  return (
    <main>
     <HeroContact/>
      <Cta/>
      <Footer/>
    </main>
  );
};

export default ContactPage;
import React from 'react';
import CareerManagement from '../../../component/Dashboard/Career/CreateJob';



const DashboardJob = () => {
  return (
    <main className='dashboard'>
     <CareerManagement/>
    </main>
  );
};

export default DashboardJob;
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { CheckBadgeIcon, ArrowLongRightIcon } from '@heroicons/react/24/outline';

const features = [
  {
    title: "Business Consulting",
    description: "We offer the best value to our clients, providing comprehensive guidance, advice, and expertise to improve productivity as they pursue business process automation.",
    icon: CheckBadgeIcon,
    borderColor: "border-[#007fc2]",
  },
  {
    title: "Internet Marketing",
    description: "We create a marketing algorithm that elevates businesses, ensuring quick reach to customers and generating significant sales through integral automated applications.",
    icon: CheckBadgeIcon,
    borderColor: "border-[#ed98b7]",
  },
  {
    title: "Software Services",
    description: "We develop corporate websites and mobile applications, helping businesses fully digitalize and conceptualize their goals with automated software solutions tailored to their models.",
    icon: CheckBadgeIcon,
    borderColor: "border-[#b7e8dd]",
  },
  {
    title: "Full Package Branding",
    description: "Branding creates unique identification for products and services. At DevDesk, we use exquisite software tools to design that align with a business's vision.",
    icon: CheckBadgeIcon,
    borderColor: "border-blue-900",
  },
];

export default function Feature() {
  const [portfolios, setPortfolios] = useState([]);

  useEffect(() => {
    axios.get('https://app.devdesk.ng/api/portfolios')
      .then(response => {
        setPortfolios(response.data.slice(0, 4)); // Display only the first 4 portfolios
      })
      .catch(error => {
        console.error('There was an error fetching the portfolios!', error);
      });
  }, []);

  return (
    <div data-aos="fade-up"
      data-aos-offset="200"
      data-aos-delay="50"
      data-aos-duration="1000" className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="flex flex-wrap -mx-6">
          <div className="w-full lg:w-7/10 px-6">
            <div className="lg:max-w-8xl">
              <h1 className="mt-2 text-4xl font-bold text-gray-900 sm:text-5xl lg:text-6xl animate__animated animate__bounce">Our Services</h1>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                We are committed to innovation, service excellence and client success.
              </p>
              <dl className="mt-10 max-w-8xl space-y-8 text-base leading-7 text-gray-600">
                <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-2">
                  {features.map((feature, index) => (
                    <div
                      key={index}
                      className={`flex bg-white rounded-xl justify-between px-8 overflow-hidden shadow-xl border border-1 p-3 ${feature.borderColor}`}
                    >
                      <feature.icon className="h-24 w-24 text-blue-600" aria-hidden="true" />
                      <div className="ml-4">
                        <p className="text-xl font-bold text-black">{feature.title}</p>
                        <p className="mt-4 text-[17px]">{feature.description}</p>
                        <Link to="/services" className="block mt-4 text-blue-500 hover:underline">Learn more</Link>
                      </div>
                    </div>
                  ))}
                </div>
              </dl>
            </div>
          </div>
          <div className="max-w-7xl mt-40">
            <h1 className="px-6 mb-10 text-4xl font-bold text-gray-900 sm:text-5xl lg:text-6xl animate__animated animate__bounce">Latest Work</h1>
            <div className="carousel carousel-center bg-gradient bg-[#574d43] rounded-xl max-w-7xl space-x-4 p-20">
              {portfolios.map((portfolio, index) => (
                <div key={index} className="carousel-item">
                  <img
                    src={portfolio.featured_image}
                    className="rounded-box w-auto h-[500px]"
                    alt={portfolio.title}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto mt-20 max-w-5xl text-center">
        <p className="text-4xl text-gray-900 sm:text-5xl lg:text-5xl animate__animated animate__bounce">
          DevDesk collaborates with businesses to meet real human needs. Our all-encompassing digital solutions create brands, experiences, and software that improve lives every day.
        </p>
        <div className="mt-8 sm:items-center sm:justify-center lg:justify-start sm:space-x-5 lg:mt-12">
          <Link
            to="/contact"
            title=""
            className="inline-flex items-center px-4 py-2 text-lg text-white transition-all duration-200 bg-[#0186ef] border border-transparent rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj justify-center hover:bg-gray-600"
            role="button"
          >
            {" "}
            Let’s Collaborate{" "} <ArrowLongRightIcon className="ml-2 w-8 h-8"/>
          </Link>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import Cta from '../../component/Home/CTA';
import Footer from '../../component/Footer/Footer';
import ServiceHero from '../../component/Services/HeroServices';

const ServicePage = () => {
  return (
    <main>
      <ServiceHero/>
      <Cta/>
      <Footer/>
    </main>
  );
};

export default ServicePage;
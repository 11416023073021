import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '../Table'; // Adjust the import path as needed

import {
  Dialog,
  Transition
} from '@headlessui/react';
import {
  BookOpenIcon,
  BriefcaseIcon,
  CircleStackIcon,
  EnvelopeIcon,
  EnvelopeOpenIcon,
  HomeModernIcon,
  UserPlusIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';

const navigation = [
  { name: 'Dashboard', href: '/dashboard', icon: HomeModernIcon },
  { name: 'Blog', href: '/dashboard/blog', icon: BookOpenIcon },
  { name: 'Contact', href: '/dashboard/contact', icon: EnvelopeOpenIcon },
  { name: 'Job', href: '/dashboard/job', icon: BriefcaseIcon },
  { name: 'Portfolio', href: '/dashboard/portfolio', icon: CircleStackIcon },
  { name: 'Admin', href: '/dashboard/admin', icon: UserPlusIcon },
  { name: 'Newsletter', href: '/dashboard/newsletter', icon: EnvelopeIcon },
];

const teams = [
  { id: 1, name: 'Update coming soon', href: '#', initial: 'A' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const API_URL = 'https://app.devdesk.ng/api';

export default function PortfolioManagement() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [portfolios, setPortfolios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    feature_image: '',
  });

  useEffect(() => {
    const fetchPortfolios = async () => {
      try {
        const response = await axios.get(`${API_URL}/portfolios`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        });
        setPortfolios(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching portfolios:', error);
        setLoading(false);
      }
    };

    fetchPortfolios();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_URL}/portfolios`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
      console.log('Portfolio created:', response.data);
      setPortfolios([...portfolios, response.data]);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error creating portfolio:', error);
    }
  };

  const handleEdit = (portfolio) => {
    setFormData(portfolio);
    setIsModalOpen(true);
  };

  return (
    <div>
      <Transition show={sidebarOpen} as={React.Fragment}>
        <Dialog
          open={sidebarOpen}
          onClose={setSidebarOpen}
          className="relative z-50 xl:hidden"
        >
          <Transition.Child
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>
          <div className="fixed inset-0 flex">
            <Transition.Child
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-900 p-6">
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button
                    type="button"
                    onClick={() => setSidebarOpen(false)}
                    className="-m-2.5 p-2.5"
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex h-16 items-center">
                  <img
                    alt="DevDesk"
                    src="/img/4.png"
                    className="h-8 w-auto"
                  />
                </div>
                <nav className="flex flex-1 flex-col mt-5">
                  <ul role="list" className="flex-1 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            'text-gray-400 hover:bg-gray-800 hover:text-white',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                          )}
                        >
                          <item.icon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                  <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {teams.map((team) => (
                      <li key={team.name}>
                        <a
                          href={team.href}
                          className={classNames(
                            'text-gray-400 hover:bg-gray-800 hover:text-white',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                          )}
                        >
                          <span className="flex h-6 w-6 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-xs font-medium text-gray-400 group-hover:text-white">
                            {team.initial}
                          </span>
                          <span className="truncate">{team.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </nav>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      <div className="hidden xl:fixed xl:inset-y-0 xl:flex xl:w-72 xl:flex-col bg-gray-900">
        <div className="flex flex-1 flex-col overflow-y-auto bg-gray-900 p-6">
          <div className="flex h-16 items-center">
            <img
              alt="DevDesk"
              src="/img/4.png"
              className="h-8 w-auto"
            />
          </div>
          <nav className="flex flex-1 flex-col mt-5">
            <ul role="list" className="flex-1 space-y-1">
              {navigation.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.href}
                    className={classNames(
                      'text-gray-400 hover:bg-gray-800 hover:text-white',
                      'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                    )}
                  >
                    <item.icon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
            <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
            <ul role="list" className="-mx-2 mt-2 space-y-1">
              {teams.map((team) => (
                <li key={team.name}>
                  <a
                    href={team.href}
                    className={classNames(
                      'text-gray-400 hover:bg-gray-800 hover:text-white',
                      'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                    )}
                  >
                    <span className="flex h-6 w-6 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-xs font-medium text-gray-400 group-hover:text-white">
                      {team.initial}
                    </span>
                    <span className="truncate">{team.name}</span>
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>

      <div className="xl:pl-72 py-4">
        <main className="lg:pr-[60px]">
          <h1 className="mx-auto ml-10 font-bold text-4xl">Portfolio Management</h1>
          <div className="p-6">
            <button
              className="ml-4 btn bg-blue-700 border-none font-normal text-[16px] focus:bg-none text-white mb-4 py-1 px-8 ring-0 rind-none"
              onClick={() => setIsModalOpen(true)}
            >
              Create Portfolio
            </button>

            <div>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <Table data={portfolios} onEdit={handleEdit} />
              )}
            </div>

            {isModalOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="modal-overlay fixed inset-0 bg-black opacity-50"></div>
                <div className="modal-container bg-white w-11/12 md:max-w-3xl mx-auto rounded shadow-lg z-50 overflow-y-auto">
                  <div className="modal-content py-4 text-left px-6">
                    <div className="flex justify-end items-center pb-3">
                      <button
                        className="modal-close cursor-pointer z-50"
                        onClick={() => setIsModalOpen(false)}
                      >
                        <span className="text-black">✕</span>
                      </button>
                    </div>
                    <h3 className="text-lg font-bold mb-4">{formData.id ? 'Edit Portfolio' : 'Create Portfolio'}</h3>
                    <form onSubmit={handleSubmit}>
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          Title
                        </label>
                        <input
                          type="text"
                          name="title"
                          value={formData.title}
                          onChange={handleChange}
                          className="w-full px-3 py-2 border rounded-md"
                          required
                        />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          Description
                        </label>
                        <textarea
                          name="description"
                          value={formData.description}
                          onChange={handleChange}
                          className="w-full px-3 py-2 border rounded-md"
                          required
                        />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          Feature Image URL
                        </label>
                        <input
                          type="text"
                          name="feature_image"
                          value={formData.feature_image}
                          onChange={handleChange}
                          className="w-full px-3 py-2 border rounded-md"
                          required
                        />
                      </div>
                      <div className="flex justify-end">
                        <button
                          type="submit"
                          className="btn bg-blue-600 text-white px-4 py-2 rounded-md"
                        >
                          {formData.id ? 'Update Portfolio' : 'Create Portfolio'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

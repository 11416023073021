import React, { useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import "animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const Hero = () => {
  AOS.init();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const openCalendlyPopup = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/devdesk-services/30min",
      });
    } else {
      console.error("Calendly is not loaded");
    }
  };

  return (
    <>
      <Navbar />
      <section className="relative py-12 sm:py-16 lg:pt-16 lg:pb-36 bg-white isolate overflow-hidden">
        <svg
          className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="0787a7c5-978c-4f66-83c7-11c213f99cb7"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M.5 200V.5H200" fill="none" />
            </pattern>
          </defs>
          <rect
            width="100%"
            height="100%"
            strokeWidth={0}
            fill="url(#0787a7c5-978c-4f66-83c7-11c213f99cb7)"
          />
        </svg>
        <div className="px-10 mx-auto sm:px-6 lg:px-20 max-w-8xl">
          <div className="grid grid-cols-1 gap-y-8 lg:items-center lg:grid-cols-5 sm:gap-y-20">
            <div className="text-center lg:col-span-3 xl:col-span-3 lg:text-left md:px-16 lg:px-0">
              <div
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="50"
                data-aos-duration="1000"
                className="max-w-sm mx-auto sm:max-w-md md:max-w-full"
              >
                <h1 className="text-4xl  lg:leading-[93px] sm:leading-[103px] font-bold  text-gray-900 sm:text-5xl  lg:text-8xl animate__animated animate__bounce">
                  We are your <span className="text-[#0186ef]">partner </span>{" "}
                  in digital transformation.
                </h1>

                <p className="mt-2 lg:text-lg sm:leading-1 text-gray-600 sm:mt-8">
                  DevDesk is an innovative cloud-based integrated studio
                  building digital <br /> human capital software for businesses.
                  We provides end-to-end services <br /> that enable busineses
                  and personal brands accelerate business processes, <br />
                  manage changes and create effective network that fosters
                  growth.
                </p>
              </div>

              <div className="mt-8 sm:flex sm:items-center sm:justify-center lg:justify-start sm:space-x-5 lg:mt-12">
  <Link
    to="/contact"
    title=""
    className="inline-flex items-center w-full sm:w-auto mb-4 sm:mb-0 px-4 py-2 text-lg text-white transition-all duration-200 bg-[#0186ef] border border-transparent rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj justify-center hover:bg-gray-600"
    role="button"
  >
    Let’s Collaborate
    <ArrowLongRightIcon className="ml-2 w-8 h-8" />
  </Link>

  <button
    onClick={openCalendlyPopup}
    className="inline-flex items-center w-full sm:w-auto px-4 py-2 text-lg text-black transition-all duration-200 border border-[#0186ef] rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj justify-center hover:bg-gray-600 hover:text-white"
  >
    Schedule a meeting
    <ArrowLongRightIcon className="ml-2 w-8 h-8" />
  </button>
</div>

            </div>

            <div className="lg:col-span-2 xl:col-span-2 lg:p-20">
  <img
    className="w-full sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg h-auto mx-auto rounded-xl"
    src="https://images.stockcake.com/public/0/0/a/00a0a503-9f5c-4659-afd8-9775914b4653_large/focused-coding-session-stockcake.jpg"
    alt="Focused coding session"
  />
</div>

          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavbarCareer from '../Navbar/NavbarCareer';


const API_URL = 'https://app.devdesk.ng/api';
export default function HeroCareer() {
  const [jobs, setJobs] = useState([]);

  // Example: Fetching jobs from an API endpoint (adjust URL as per your backend)
  useEffect(() => {
    fetch(`${API_URL}/careers`)
      .then(response => response.json())
      .then(data => setJobs(data))
      .catch(error => console.error('Error fetching jobs:', error));
  }, []);

  return (
    <>
      <section className="w-full px-6 pb-12 bg-[#ed98b7]">
        <NavbarCareer />
        <div className="mx-auto max-w-5xl">
          <div className="container px-4 py-32 mx-auto mt-px text-left md:max-w-none">
            <p className="text-4xl lg:leading-[138px] tracking-tighter sm:leading-[103px] font-bold text-gray-900 sm:text-5xl lg:text-9xl animate__animated animate__bounce">
              We are a remote based team
            </p>
            <div className="mt-8 sm:flex sm:items-center sm:justify-center lg:justify-start sm:space-x-5 lg:mt-12">
            
              <Link
                  href="#"
                  onClick={() => document.getElementById('my_modal_3').showModal()}
                  title=""
                  className="inline-flex  rounded-full items-center px-8 py-4 text-lg text-white transition-all duration-200 bg-[#0186ef] border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj justify-center hover:bg-gray-600"
                  role="button"
                >
                  {" "}
                  See Openings{" "}
                </Link>
            </div>
          </div>
        </div>

        <div className="mx-auto max-w-4xl mt-20">
          <p className="m-0 text-xl font-semibold leading-tight border-0 text-black lg:text-6xl md:text-2xl">
            Join Our Team at DevDesk
          </p>
          <p className="pt-4 pb-10 m-0 leading-7 text-gray-700 border-0 sm:pr-12 xl:pr-32 lg:text-2xl">
            Are you ready to shape the future of digital solutions? At DevDesk, we believe in
            fostering a collaborative and innovative environment where every team member can
            thrive.
          </p>
        </div>
        <div className="mx-auto max-w-4xl mt-20">
          <p className="m-0 text-xl font-semibold leading-tight border-0 text-black lg:text-6xl md:text-2xl">
            Why DevDesk?
          </p>
          <p className="pt-4 pb-10 m-0 leading-7 text-gray-700 border-0 sm:pr-12 xl:pr-32 lg:text-2xl">
            Innovate with us, be at the forefront of digital transformation, working on
            cutting-edge projects that redefine industries. Grow your career With ongoing learning
            and development opportunities, we support your career growth and aspirations. We have a
            collaborative culture, work alongside passionate professionals who are dedicated to
            making a real impact. We value diversity and strive to create an inclusive workplace
            where everyone can contribute and succeed.
          </p>
        </div>
        <div className="mx-auto mt-20 max-w-5xl text-center">
          <p className="text-4xl text-black sm:text-5xl lg:text-5xl animate__animated animate__bounce">
            Ready to join a dynamic team and take your career to the next level? Apply today and be
            a part of the DevDesk journey. Together, we can achieve great things.
          </p>
          <div className="mt-8 sm:items-center sm:justify-center lg:justify-start sm:space-x-5 lg:mt-12">
            <button
              className="inline-flex items-center px-4 py-2 text-lg text-white transition-all duration-200 bg-[#0186ef] border border-transparent rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj justify-center hover:bg-gray-600 btn"
              onClick={() => document.getElementById('apply_now_modal').showModal()}
            >
              Apply today
            </button>
          </div>
        </div>
      </section>

      {/* Modal for displaying job openings */}
      <dialog id="my_modal_3" className="modal">
        <div className="modal-box max-w-5xl">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => document.getElementById('my_modal_3').close()}>✕</button>
          <div className="overflow-x-auto">
            <table className="table">
              <thead>
                <tr>
                  <th className='text-2xl'>Job Title</th>
                  <th className='text-2xl'>Description</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {jobs.map(job => (
                  <tr key={job.id}>
                    <td className='font-bold text-1xl'>{job.title}</td>
                    <td>{job.description}</td>
                    <td> <button
              className="inline-flex items-center px-4 py-2 text-lg text-white transition-all duration-200 bg-[#0186ef] border border-transparent rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj justify-center hover:bg-gray-600 btn"
              onClick={() => document.getElementById('apply_now_modal').showModal()}
            >
              Apply 
            </button></td>
                  </tr>
                ))}
              </tbody>
             
            </table>
          </div>
        </div>
      </dialog>

      {/* Modal for applying to job */}
      <dialog id="apply_now_modal" className="modal">
        <div className="modal-box">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => document.getElementById('apply_now_modal').close()}>✕</button>
          <h3 className="font-bold text-4xl">To Apply Now for!</h3>
        <p className="text-2xl mt-4 ">send your CV and the role to<span><a className='ml-3 text-blue-600' href='mailto:careers@devdesk.ng'>careers@devdesk.ng</a> </span>   </p>  
        </div>
      </dialog>
    </>
  );
}

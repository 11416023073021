import React from 'react';
import HomePage from "../src/pages/home";
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import AboutPage from "./pages/about";
import ServicePage from "./pages/services";
import WorkPage from "./pages/work";
import CareerPage from "./pages/career";
import BlogPage from "./pages/blog";
import ContactPage from "./pages/contact";
import DashboardPage from "./pages/dashboard";
import LoginPage from "./pages/login";

import PrivateRoute from './component/PrivateRoute';
import DashboardBlog from './pages/dashboard/blog';
import DashboardAdmin from './pages/dashboard/admin';
import Post from './component/Blog/Post';
import DashboardPortfolio from './pages/dashboard/portfolio.jsx';
import DashboardJob from './pages/dashboard/job/index.jsx';
import DashboardNewsletter from './pages/dashboard/newsletter/index.jsx';
import DashboardContact from './pages/dashboard/contact/index.jsx';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/services" element={<ServicePage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/work" element={<WorkPage />} />
        <Route path="/career" element={<CareerPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route exact path="/dashboard" element={<PrivateRoute> <DashboardPage /></PrivateRoute>} />
        <Route path="/dashboard/admin" element={<PrivateRoute> <DashboardAdmin/></PrivateRoute>} />
        <Route path="/dashboard/blog" element={<PrivateRoute> <DashboardBlog /></PrivateRoute>}/>
        <Route exact path="/dashboard/portfolio" element={<PrivateRoute> <DashboardPortfolio /></PrivateRoute>} />
        <Route exact path="/dashboard/job" element={<PrivateRoute> <DashboardJob /></PrivateRoute>} />
        <Route exact path="/dashboard/newsletter" element={<PrivateRoute> <DashboardNewsletter /></PrivateRoute>} />
        <Route exact path="/dashboard/contact" element={<PrivateRoute> <DashboardContact /></PrivateRoute>} />

        



        
        <Route path="/post/:postId" element={<Post />} />
      </Routes>
    </Router>
  );
};

export default App;

import { Link } from "react-router-dom"
import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
export default function About() {
  return (
    <>
    
    <section class="px-2 py-10 lg:py-32 sm:py-28 bg-gray-100 md:px-0">
  <div data-aos="fade-up"
    data-aos-offset="200"
    data-aos-delay="50"
    data-aos-duration="1000" class="container items-center max-w-7xl px-8 mx-auto xl:px-5">
    <div class="flex flex-wrap items-center sm:-mx-3">
      <div class="w-full md:w-1/2 md:px-3">
        <div class="w-full pb-6 space-y-6 sm:max-w-md lg:max-w-lg md:space-y-4 lg:space-y-6 xl:space-y-6 sm:pr-5 lg:pr-0 md:pb-0">
          <h1 class="text-4xl font-bold  text-gray-900 sm:text-5xl  lg:text-6xl animate__animated animate__bounce">
            <span class="block xl:inline">About DevDesk</span>
          </h1>
          <p class="mx-auto text-base text-gray-500 sm:max-w-md lg:text-xl md:max-w-3xl">DevDesk is an innovative cloud-based integrated digital human capital,
software for business solution and Buz/Tech information distributing platform
that provides end-to-end services to enable companies, start-ups and personal
brand accelerate business processes, manage changes and create effective
network that fosters growth.</p>

<div className="mt-8 sm:items-center sm:justify-center lg:justify-start sm:space-x-5 lg:mt-12">
                <Link
                  to="/about"
                  className="inline-flex hover:underline items-center px-4 py-2 text-lg transition-all duration-200 text-[#0186ef] border border-transparent rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj justify-center hover:text-gray-900"
                  
                >
                  {" "}
                  Read More{" "} <ArrowLongRightIcon className="ml-2 w-8 h-8"/>
                </Link>
               
              </div>
         
        </div>
      </div>
      <div class="w-full md:w-1/2">
        <div class="w-full h-auto overflow-hidden rounded-md shadow-xl sm:rounded-xl" data-rounded="rounded-xl" data-rounded-max="rounded-full">
            <img src="https://images.unsplash.com/photo-1581094651181-35942459ef62?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="b"/>
          </div>
      </div>
    </div>
  </div>
</section>

    </>
   
  )
}
